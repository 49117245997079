<template>
	<!-- 商品列表页面 -->
	<div class="container">
		<a-row>
			<a-col :span="24" class="right">
				<div class="right-content">
					<!-- 筛选 -->
					<div class="table-page-search-wrapper">
						<a-form layout="inline">
							<a-row :gutter="40">
								<a-col :md="6" :sm="24">
									<!-- 筛选 -->
									<a-form-item label="参数名">
										<a-input type="text" placeholder="请输入" v-model="queryParam.name"></a-input>
									</a-form-item>
								</a-col>
								<a-col :md="6" :sm="24">
									<a-button type="primary" @click="searchTableData">搜索</a-button>
									<a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
								</a-col>
							</a-row>
						</a-form>
					</div>
					<!-- 添加商品按钮 -->
					<div class="table-operator">
						<a-button type="primary" @click="$refs.addForm.add(queryParam.categoryId)">新增参数名</a-button>
					</div>
					<!-- 表格 -->
					<a-table :columns="columns" :data-source="data" :scroll="{ x: 1300, y: 800 }" :loading="loading"
						:pagination="false">
						<span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
						  <a-switch
						    checked-children="启用"
						    un-checked-children="禁用"
						    v-model="record.checked"
						    @change="changeSwitch"
						  />
						</span>
						<!-- 商品图片插槽 -->
						<!-- 操作插槽 -->
						<span slot="action" slot-scope="text, record">
							<a @click="$refs.editForm.edit(record)">编辑</a>
							<a-divider type="vertical" />
							<!--竖线-->
							<a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
								<a>删除</a>
							</a-popconfirm>
						</span>
					</a-table>
					<!-- 分页组件 -->
					<div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
						<a-pagination
							:pageSize="page.pageSize"
							:current="page.pageNo"

							show-size-changer
							:page-size-options="['10','20','50','100']"

							show-quick-jumper
							
							:total="total"
							:show-total="(total) => `共 ${total} 条`"
							@change="pageChange"
							@showSizeChange="sizeChange"
						></a-pagination>
					</div>
				</div>
			</a-col>
		</a-row>
		<!-- 引入子组件 -->
		<add-form @ok="initTableData" ref="addForm"></add-form>
		<edit-form @ok="getList" ref="editForm"></edit-form>
	</div>
</template>

<script>
	import {
		addParamsName,
		editParamsName,
		getParamsNameDetail,
		queryParamsNamePageList,
		changeParamsNameStatus,
	} from "@/api/modular/mallLiving/paramsName/paramsName";
	import addForm from "./addParamsForm.vue";
	import editForm from './editParamsForm.vue';
	import {
		query
	} from 'quill'

	const columns = [{
			title: '序号',
			align: 'center',
			width: '80px',
			customRender: (text, record, index) => `${index + 1}`,
		},
		{
			title: '参数名',
			dataIndex: 'name',
			key: 'name',
			width: '260px',
		},
		{
			title: '排序值',
			dataIndex: 'sort',
			align: 'center',
			width: '150px',
		},
		{
			title: '状态',
			key: 'delFlag',
			dataIndex: 'delFlag',
			scopedSlots: {
				customRender: 'delFlag',
			},
			align: 'center',
			width: '160px',
		},
		{
			title: '操作',
			align: 'center',
			dataIndex: 'action',
			width: '410px',
			key: 'action',
			fixed: 'right',
			scopedSlots: {
				customRender: 'action',
			},
		},
	]

	export default {
		components: { addForm, editForm },
		data() {
			return {
				modalTitle: "新增参数名",
				confirmLoading: false,
				columns,
				currentItem: null,
				currentSpecItem: null,
				data: [],
				loading: false,
				visible: false,
				page: {
					pageNo: 2,
					pageSize: 10,
				},
				total: 0,
				classList: [], //顶部商品分类筛选数
				queryParam: {
					name: '',
					categoryId: ''
				}
			}
		},
		mounted() {
			this.queryParam.categoryId = this.$route.query.goodsCategoryId
			this.initTableData()
		},
		methods: {
			pageChange(pageNo,pageSize){
				this.page.pageNo = pageNo
				this.page.pageSize = pageSize
				this.getList()
			},
			sizeChange(pageNo, pageSize){
				this.page.pageNo = pageNo
				this.page.pageSize = pageSize
				this.getList()
			},
			handleCancel(){
				this.visible = false
			},
			initTableData() {
				this.page.pageNo = 1
				this.getList()
			},
			searchTableData() {
				this.initTableData()
			},
			getList() {
				this.loading = true
				var params = {
					page: this.page,
					queryParam: this.queryParam,
				}
				queryParamsNamePageList(params)
					.then((res) => {
						if (res.success) {
							this.loading = false
							this.data = res.data.rows
							this.total = res.data.totalRows
							this.data.forEach((item) => {
								item.checked = item.delFlag == 1
							})
						}
					})
					.finally((res) => {
						setTimeout(() => {
							this.loading = false
						}, 5000)
					})
			},
			// 删除
			sysItemDelete(record) {
				this.recordItem = record
				this.changeParamsNameStatus(2) //2:删除
			},
			clickSwitch(record) {
				this.recordItem = record
			},
			changeSwitch(e) {
				let that = this
				let delFlag = e ? 1 : 3
				setTimeout(function() {
					that.changeParamsNameStatus(delFlag)
				}, 300)
			},
			async changeParamsNameStatus(delFlag) {
				let params = {
					delFlag: delFlag,
					id: this.recordItem.id,
				}
				let data = await changeParamsNameStatus(params)
				var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
				if (data.code == 200) {
					this.$message.success(this.recordItem.name + ' 已' + text)
					this.getList()
				}
			}
		},
	}
</script>

<style lang="less" scoped>
	// @import url(); 引入公共css
	.container {
		min-width: 1000px;
	}

	.title {
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		font-weight: bold;
	}

	.right {
		border-radius: 2px;
		background-color: rgba(255, 255, 255, 100);
	}

	.right-content {
		box-sizing: border-box;
		padding: 20px;
	}

	.icon {
		font-size: 20px;
		margin: 0 5px;
	}

	img {
		width: 30px;
		height: 30px;
	}
</style>
